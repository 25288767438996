import React from "react";






const Pay = () => {

  return (
    <>
        <p>pay</p>
    </>
  )
}


export default Pay;